<template>
  <div class="customers p-8">
    <div class="mx-auto max-w-5xl space-y-6">
      <router-link
        :to="`${parent}`"
        class="flex justify-start items-center gap-1"
      >
        <svg
          class="h-8 w-8 text-blue-600 hover:text-blue-700"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
          />
        </svg>
        <h1 class="text-3xl font-semibold">Edit Customer</h1>
      </router-link>

      <div v-if="isLoading" class="flex justify-center gap-1">
        <div class="spinner dark"></div>
        <div>Loading</div>
      </div>

      <form @submit.prevent="submitCustomer" class="max-w-3xl space-y-5">
        <div class="text-lg space-y-5">
          <div>
            <label
              for="billing_business"
              class="block text-sm font-medium text-gray-600"
            >
              Business Name
            </label>
            <div class="mt-1">
              <input
                v-model="customer.billing_business"
                name="billing_business"
                class="w-full border rounded px-4 py-2"
              />
            </div>
          </div>

          <div>
            <label
              for="customer_name"
              class="block text-sm font-medium text-gray-600"
            >
              Name <span class="text-red-600">*</span>
            </label>
            <div class="mt-1">
              <input
                v-model="customer.customer_name"
                required
                name="customer_name"
                class="w-full border rounded px-4 py-2"
              />
            </div>
          </div>

          <div>
            <label
              for="customer_phone"
              class="block text-sm font-medium text-gray-600"
            >
              Phone (xxx) xxx-xxxx <span class="text-red-600">*</span>
            </label>
            <div class="mt-1">
              <input
                @change="
                  customer.customer_phone = formatPhone(customer.customer_phone)
                "
                v-model="customer.customer_phone"
                required
                type="tel"
                pattern="[+1(\s]*[2-9]{1}[0-9]{2}[-.)\s]*[0-9]{3}[-.\s]*[0-9]{4}"
                name="customer_phone"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              />
            </div>
          </div>

          <div>
            <label
              for="customer_email"
              class="block text-sm font-medium text-gray-600"
            >
              Email <span class="text-red-600">*</span>
            </label>
            <div class="mt-1">
              <input
                v-model="customer.customer_email"
                required
                type="email"
                name="customer_email"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              />
            </div>
          </div>

          <div>
            <label
              for="customertype_name"
              class="block text-sm font-medium text-gray-600"
            >
              Customer Type
            </label>
            <div class="mt-1">
              <select
                v-model="customer.customer_type"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              >
                <option value=""> </option>
                <option
                  v-for="customertype in customer_types"
                  :key="customertype.id"
                  :value="customertype.id"
                >
                  {{ customertype.customertype_name }}
                </option>
              </select>
            </div>
          </div>

          <div>
            <label
              for="customer_notes"
              class="block text-sm font-medium text-gray-600"
            >
              Customer Notes (Internal Use)
            </label>
            <div class="mt-1">
              <textarea
                v-model="customer.customer_notes"
                name="customer_notes"
                class="w-full border rounded px-4 py-2 border-gray-200"
                rows="5"
              />
            </div>
          </div>

          <div>
            <label
              for="billing_phone"
              class="block text-sm font-medium text-gray-600"
            >
              Billing Phone (xxx-xxx-xxxx)
            </label>
            <div class="mt-1">
              <input
                @change="
                  customer.billing_phone = formatPhone(customer.billing_phone)
                "
                v-model="customer.billing_phone"
                type="tel"
                pattern="[+1(\s]*[2-9]{1}[0-9]{2}[-.)\s]*[0-9]{3}[-.\s]*[0-9]{4}"
                name="billing_phone"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              />
            </div>
          </div>

          <div>
            <label
              for="billing_address1"
              class="block text-sm font-medium text-gray-600"
            >
              Billing Address
            </label>
            <div class="mt-1">
              <input
                v-model="customer.billing_address1"
                name="billing_address1"
                class="w-full border rounded px-4 py-2"
              />
            </div>

            <div class="mt-3">
              <input
                v-model="customer.billing_address2"
                name="billing_address2"
                class="w-full border rounded px-4 py-2"
              />
            </div>

            <div class="mt-3 flex gap-2">
              <div class="w-full">
                <label
                  for="billing_city"
                  class="block text-sm font-medium text-gray-600"
                >
                  City
                </label>
                <input
                  v-model="customer.billing_city"
                  name="billing_city"
                  class="w-full border rounded px-4 py-2"
                />
              </div>
              <div class="w-full">
                <label
                  for="billing_state"
                  class="block text-sm font-medium text-gray-600"
                >
                  State
                </label>
                <select
                  v-model="customer.billing_state"
                  name="billing_state"
                  class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
                >
                  <option value=""> </option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>
              <div class="w-full">
                <label
                  for="billing_zip"
                  class="block text-sm font-medium text-gray-600"
                >
                  Zip
                </label>
                <input
                  v-model="customer.billing_zip"
                  name="billing_zip"
                  class="w-full border rounded px-4 py-2"
                />
              </div>
            </div>
          </div>

          <div>
            <label
              for="customer_status"
              class="block text-sm font-medium text-gray-600"
            >
              Status
            </label>
            <div class="mt-1">
              <select
                v-model="customer.customer_status"
                required
                name="customer_status"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
          </div>

          <div class="pt-2 flex justify-center">
            <button
              type="submit"
              class="bg-blue-600 submit-btn text-white text-2xl font-bold w-60 rounded py-2 flex justify-center gap-2"
            >
              <div v-if="isPending" class="spinner"></div>
              Save
            </button>
          </div>

          <div
            v-if="error || write_err"
            class="text-center text-red-600 font-semibold"
          >
            {{ error }} {{ write_err }} {{ error_customertypes }}
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";
import usePhone from "@/composables/usePhone";
import getCollection from "@/composables/getCollection";

export default {
  props: ["customer_id", "user"],
  setup(props) {
    const isLoading = ref(true);
    const isPending = ref(false);
    const route = useRoute();
    const router = useRouter();
    const parent = route.path.substring(0, route.path.lastIndexOf("/"));

    const business_id = props.user.claims.business_id ?? props.id;

    const { error, document: customer } = getDocument(
      `businesses/${business_id}/customers`,
      props.customer_id
    );

    const {
      error: error_customertypes,
      documents: customer_types,
    } = getCollection(`businesses/${business_id}/customer_types`);

    watchEffect(() => {
      if (customer.value) {
        isLoading.value = false;
      }
      if (customer_types.value) {
        customer_types.value = customer_types.value.sort((a, b) =>
          a.customertype_name > b.customertype_name ? 1 : -1
        );
      }
    });

    const { error: write_err, updateDoc } = useDocument(
      `businesses/${business_id}/customers`,
      props.customer_id
    );

    const { error: phone_err, formatPhone } = usePhone();

    const submitCustomer = async () => {
      isPending.value = true;
      await updateDoc({
        customer_name: customer.value.customer_name,
        customer_phone: customer.value.customer_phone,
        customer_email: customer.value.customer_email,
        customer_notes: customer.value.customer_notes ?? "",
        customer_type: customer.value.customer_type ?? "",
        billing_business: customer.value.billing_business ?? "",
        billing_phone: customer.value.billing_phone ?? "",
        billing_address1: customer.value.billing_address1 ?? "",
        billing_address2: customer.value.billing_address2 ?? "",
        billing_city: customer.value.billing_city ?? "",
        billing_state: customer.value.billing_state ?? "",
        billing_zip: customer.value.billing_zip ?? "",
        customer_status: customer.value.customer_status,
      });
      isPending.value = false;
      if (!write_err.value) {
        router.push(parent);
      }
    };

    return {
      isLoading,
      isPending,
      parent,
      error,
      customer,
      write_err,
      phone_err,
      customer_types,
      formatPhone,
      submitCustomer,
      error_customertypes,
    };
  },
};
</script>
